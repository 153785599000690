import './Airdrop.css';
import icon from '../../assets/images/airdrop-icon.webp'
import { useContext, useEffect, useState } from 'react';
import { TranslationContext } from '../../assets/contexts/translationContext';
import { FRIENDS_LIMIT } from '../../assets/utils/constants';
import mainApi from '../../assets/api/MainApi';
import MiniPreloader from '../MiniPreloader/MiniPreloader';

function Airdrop({ user, referral }) {
    const tg = window.Telegram?.WebApp

    const {
        airdrop: {
            title,
            dateTitle,
            dateValue,
            reqTitle,
            friendsTitle,
            friendsText,
            otherTitle,
            otherValue,
        },
    } = useContext(TranslationContext);

    const [friends, setFriends] = useState(null)
    const [preloaders, setPreloaders] = useState({
        friends: true,
    })

    useEffect(() => {
        if (!user) return
        setPreloaders(prevValue => ({
            ...prevValue,
            friends: true,
        }))
        mainApi
            .getFriends({ limit: FRIENDS_LIMIT })
            .then((res) => {
                setFriends(res.data);
                // setFriends(FRIENDS_CARDS)
                console.log(res.data);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setPreloaders(prevValue => ({
                    ...prevValue,
                    friends: false,
                }))
            })
    }, [user])


    return (
        <div className='airdrop'>
            <img className="airdrop__icon" src={icon} alt='' />
            <h3 className='airdrop__title'>{title}</h3>
            <div className='airdrop__info'>
                <div className='airdrop__info-box airdrop__info-box_main'>
                    <p className='airdrop__info-title'>{dateTitle}</p>
                    <p className='airdrop__info-value'>{dateValue}</p>
                </div>
            </div>
            <h3 className='airdrop__req-title'>{reqTitle}</h3>
            <div className='airdrop__info'>
                <div className='airdrop__info-box'>
                    <p className='airdrop__info-title'>{friendsTitle}</p>
                    {preloaders.friends ?
                        <MiniPreloader isLinkColor={true} />
                        :
                        <p className='airdrop__info-value'>{friendsText} {friends && friends.length - (referral?.parent ? 1 : 0) > 0 ? friends.length - (referral?.parent ? 1 : 0) >= FRIENDS_LIMIT ? `+${FRIENDS_LIMIT}` : friends.length - (referral?.parent ? 1 : 0) : '0'}</p>
                    }
                </div>
                <div className='airdrop__info-box'>
                    <p className='airdrop__info-title' onClick={() => {
                        tg?.openInvoice('https://t.me/$kSnkaqxjAVAwAgAAK09uPB5fS0o', (status) => {
                            if (status) {
                                // 'cancelled'
                                // 'paid'
                            } else {
                                console.log('Phone number not shared');
                            }
                        });
                    }}>{otherTitle}</p>
                    <p className='airdrop__info-value'>{otherValue}</p>
                </div>
            </div>
        </div>
    );
}


export default Airdrop;