export const RU_TRANSLATION = {
    loading: {
        text: 'Загрузка...',
        error: 'Ошибка загрузки',
        developed: 'Разработано Caba Hub'
    },
    header: {
        incomeInfoText: `Проводите инкассацию раз в 4 часа, чтобы не прерывать развитие франшизы.
Самостоятельный рост прибыли останавливается спустя 4 часа после вашего последнего визита в виртуальный «Союз-99».`,
        level: 'Уровень',
        income: 'Прибыль в час',
        rating: 'Рейтинг',
        airdropBtn: 'AirDrop',
    },
    phonePopup: {
        text: 'Для создания виртуальной франшизы поделитесь номером телефона',
        shareBtn: 'Поделиться номером',
    },
    incomePopup: {
        text: 'Вы заработали',
        okBtn: 'ОК',
    },
    learnPopup: {
        next: 'Далее',
        start: 'Старт',
        slides: [
            {
                title: 'Франшиза',
                text: 'Станьте владельцем виртуальной франшизы лаундж-бара «Союз-99».'
            },
            {
                title: 'Прибыль',
                text: 'Зарабатывайте монеты, нажимая на экран. Переводите их в бонусы, скидки и подарки в «Союзе-99».'
            },
            {
                title: 'Улучшайте меню',
                text: `Прокачивайте карточки меню,
            проходя обучение в головном офисе
            на Кутузовском проспекте.
            Это увеличит вашу ежечасную прибыль.`
            },
            {
                title: 'Развивайте бизнес-стратегию',
                text: `Сделайте вашу франшизу лучшей, совершенствуя её характеристики.
            Приобретайте улучшители за монеты, поднимайте уровень бизнеса и прибыль.`
            },
            {
                title: 'Создавайте сообщества',
                text: 'Приглашайте друзей стать частью франшизы. Вы и ваш друг получите бонусную сумму монет на ваши счета.'
            },
            {
                title: 'Лучший в галактике',
                text: 'Станьте лидером среди владельцев виртуальных франшиз и получите уникальный абонемент в лаундж-бар!'
            },
        ]
    },
    giftPopup: {
        receiveGift: 'Получите подарок',
        receiveGiftFrom: 'Получите подарок от',
        receiveBtn: 'Получить',
    },
    main: {
        allRewards: `Все<br>Награды`,
        fillProfile: `Заполнить 
профиль`,
        clickPromo: 'Кликай по мне!',
    },
    popupTask: {
        buyBtn: 'Получить',
        rewardEnded: 'Упс, награда закончилась',
        notEnoughtMoney: 'У вас недостаточно средств',
    },
    rewards: {
        title: 'Доступные награды',
    },
    raiting: {
        title: 'РЕЙТИНГ',
        place: 'место',
        name: 'имя',
        placeVisits: 'посещений Союза',
        coins: 'монеты',
    },
    navBlock: {
        links: [
            {
                title: 'Старт',
            },
            {
                title: 'Меню',
            },
            {
                title: 'Бизнес',
            },
            {
                title: 'Друзья',
            },
            {
                title: 'Профиль',
            },
        ],
    },
    forComputer: {
        text: `Пожалуйста, откройте
на мобильном устройстве`,
        developedText: 'Разработано Caba Hub',
    },
    friends: {
        title: 'Пригласите друзей!',
        description: 'Вы и ваш друг получите бонусы',
        inviteTitle: 'Пригласить друга',
        inviteSubtitle: 'для вас и вашего друга',
        yourFriends: 'Ваши друзья',
        emptyList: 'Список друзей пуст',
        inviteBtn: 'Пригласить друга',
        inviteText: `Я приглашаю тебя вступить в клуб владельцев виртуальной франшизы «Союз-99» 🚀

Прокачивай свою онлайн-франшизу и трать прибыль в главном лаундж-баре на Кутузовском просп., 36, стр. 41. Прямо у офиса Сбера.
        
🪐 5000 монет на старте в подарок
🌟 виртуальная игра с реальной прибылью`
    },
    friendsCard: {
        errorText: 'Ошибка',
        inviteText: 'Пригласить в Союз',
        invitedText: 'Приглашен в Союз',
    },
    business: {
        title: 'Бизнес',
    },
    businessPopup: {
        incomeText: 'Прибыль в час:',
        buyBtn: 'Получить',
        notEnoughtMoney: 'У вас недостаточно средств',
        maxLVL: 'Максимальный уровень',
        maxLVLTitle: 'Вы достигли совершенства!',
        maxLVLDescription: 'Этот аспект бизнеса достиг своей вершины развития. Продолжайте в том же духе!',
    },
    businessCard: {
        incomeText: 'Прибыль в час:',
    },
    levelUpPopup: {
        title: 'Level Up!',
        closeBtn: 'Закрыть',
    },
    menu: {
        title: 'Меню',
        infoText: `Приобретайте позиции из меню в головном отделе «Союза-99» на Кутузовском проспекте и прокачивайте карточки меню в собственной франшизе.
   

Повышая уровень меню, вы увеличиваете ежечасную прибыль вашего бизнеса, которую можете потратить на дальнейшее развитие бизнеса или активирование бонусов в лаундж-баре.`
    },
    menuCard: {
        incomeText: 'Прибыль в час:',
    },

    menuCardPopup: {
        incomeText: 'Прибыль в час',
        levelText: 'Уровень',
        purchasesText: 'Покупки',
    },
    profile: {
        links: [
            {
                nav_title: 'Награды',
            },
            {
                nav_title: 'Архив',
            },
            {
                nav_title: 'Инфо',
            }
        ],
        addToWalletBtn: 'Добавить в Wallet',
        myRewardsTitle: 'Мои награды',
        emptyRewardsList: 'Список наград пуст',
        archiveTitle: 'Архив наград',
        emptyArchive: 'Архив пуст',
        personalDataTitle: 'Личные данные',
    },
    profileInfo: {
        editBtn: 'Изменить',
        hint: 'Заполните профиль и получите 1500 монет',
        nameTitle: 'Имя',
        surnameTitle: 'Фамилия',
        genderTitle: 'Пол',
        dateOfBirthTitle: 'Дата рождения',
        emailTitle: 'Эл. почта',
        saveBtn: 'Сохранить',
        maleValue: 'Мужской',
        femaleValue: 'Женский',
    },
    airdrop: {
        title: 'Union Coin',
        dateTitle: 'Дата:',
        dateValue: 'TBA',
        reqTitle: 'Условия',
        friendsTitle: 'Друзей:',
        friendsText: 'Минимально 2, у вас - ',
        otherTitle: 'Другое',
        otherValue: 'TBA',
    },
};