export const EN_TRANSLATION = {
  loading: {
    text: 'Loading...',
    error: 'Loading error',
    developed: 'Developed by Caba Hub'
  },
  header: {
    incomeInfoText: `Collect your earnings every 4 hours to keep your franchise's development uninterrupted. 
Automatic profit growth stops 4 hours after your last visit to the virtual "Soyuz-99".`,
    level: 'Level',
    income: 'Income in hour',
    rating: 'Rating',
    airdropBtn: 'AirDrop',
  },
  phonePopup: {
    text: 'To create a virtual franchise, share your phone number',
    shareBtn: 'Share phone number',
  },
  incomePopup: {
    text: 'You have earned',
    okBtn: 'OK',
  },
  learnPopup: {
    next: 'Next',
    start: 'Start',
    slides: [
      {
        title: 'Franchise',
        text: 'Become the owner of a virtual franchise of the lounge bar "Soyuz-99".'
      },
      {
        title: 'Profit',
        text: 'Earn coins by tapping the screen.'
      },
      {
        title: 'Upgrade the Menu',
        text: `Enhance menu cards
by undergoing training at the headquarters.
This will increase your hourly income.`
      },
      {
        title: 'Develop Business Strategy',
        text: `Make your franchise the best by improving its features.
Purchase upgrades with coins, increase your business level and profits.`
      },
      {
        title: 'Create Communities',
        text: 'Invite friends to become part of the franchise. You and your friend will receive a bonus amount of coins to your accounts.'
      },
      {
        title: 'Best in the Galaxy',
        text: 'Become a leader among virtual franchise owners and receive a unique rewards!'
      },
    ]
  },
  giftPopup: {
    receiveGift: 'Receive a gift',
    receiveGiftFrom: 'Receive a gift from',
    receiveBtn: 'Receive',
  },
  main: {
    allRewards: `All<br>Rewards`,
    fillProfile: `Complete 
profile`,
    clickPromo: 'Click me!',
  },
  popupTask: {
    buyBtn: 'Get',
    rewardEnded: 'Oops, the reward has ended',
    notEnoughtMoney: `You don't have enough funds`,
  },
  rewards: {
    title: 'Available Rewards',
  },
  raiting: {
    title: 'RATING',
    place: 'Place',
    name: 'Name',
    placeVisits: 'Visits',
    coins: 'Coins',
  },
  navBlock: {
    links: [
      {
        title: 'Start',
      },
      {
        title: 'Menu',
      },
      {
        title: 'Business',
      },
      {
        title: 'Friends',
      },
      {
        title: 'Profile',
      },
    ],
  },
  forComputer: {
    text: `Please open
on a mobile device`,
    developedText: 'Developed by Caba Hub',
  },
  friends: {
    title: 'Invite Friends!',
    description: 'You and your friend will receive bonuses',
    inviteTitle: 'Invite a Friend',
    inviteSubtitle: 'for you and your friend',
    yourFriends: 'Your Friends',
    emptyList: 'Friends list is empty',
    inviteBtn: 'Invite a Friend',
    inviteText: `I invite you to join the club of virtual franchise owners of "Soyuz-99" 🚀

Upgrade your online franchise.

🪐 5,000 coins as a welcome gift
🌟 A virtual game with real profits`
  },
  friendsCard: {
    errorText: 'Error',
    inviteText: 'Invite to Soyuz',
    invitedText: 'Invited to Soyuz',
  },
  business: {
    title: 'Business',
  },
  businessPopup: {
    incomeText: 'Income in hour:',
    buyBtn: 'Get',
    notEnoughtMoney: `You don't have enough funds`,
    maxLVL: 'Maximum Level',
    maxLVLTitle: 'You have reached perfection!',
    maxLVLDescription: 'This aspect of your business has reached its peak development. Keep up the good work!',
  },
  businessCard: {
    incomeText: 'Income in hour',
  },
  levelUpPopup: {
    title: 'Level Up!',
    closeBtn: 'Close',
  },
  menu: {
    title: 'Menu',
    infoText: `Purchase menu items and upgrade menu cards in your own franchise.

By increasing the level of the menu, you boost your hourly business profit, which you can spend on further business development or activating bonuses in the lounge bar.`
  },
  menuCard: {
    incomeText: 'Income in hour:',
  },
  menuCardPopup: {
    incomeText: 'Income in hour',
    levelText: 'Level',
    purchasesText: 'Purchases',
  },
  profile: {
    links: [
      {
        nav_title: 'Rewards',
      },
      {
        nav_title: 'Archive',
      },
      {
        nav_title: 'Info',
      }
    ],
    addToWalletBtn: 'Add to Wallet',
    myRewardsTitle: 'My Rewards',
    emptyRewardsList: 'Rewards list is empty',
    archiveTitle: 'Rewards Archive',
    emptyArchive: 'Archive is empty',
    personalDataTitle: 'Personal Data',
  },
  profileInfo: {
    editBtn: 'Edit',
    hint: 'Complete your profile and receive 1,500 coins',
    nameTitle: 'First Name',
    surnameTitle: 'Last Name',
    genderTitle: 'Gender',
    dateOfBirthTitle: 'Date of Birth',
    emailTitle: 'Email',
    saveBtn: 'Save',
    maleValue: 'Male',
    femaleValue: 'Female',
  },
  airdrop: {
    title: 'Union Coin',
    dateTitle: 'Date:',
    dateValue: 'TBA',
    reqTitle: 'Conditions',
    friendsTitle: 'Friends:',
    friendsText: 'Minimum 2, you have -',
    otherTitle: 'Other',
    otherValue: 'TBA',
  },
};
